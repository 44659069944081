import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { useTranslation } from "react-i18next"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import PageHeader from "./PageHeader"
import Card from "../components/Card"
import PageTitle from "../components/PageTitle"

const SidingTypeTemplate = ({
  pageContext,
  data: {
    type: { data },
  },
}) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"

  const nameMn = data?.nameMn ?? undefined
  const nameEn = data?.nameEn ?? undefined
  const name = mn ? nameMn : nameEn

  const textMn = data?.textMn ?? undefined
  const textEn = data?.textEn ?? undefined
  const text = mn ? textMn : textEn

  const sharpPath = data?.Image ?? undefined
  const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt={name} className="w-full" />
  ) : undefined

  const sidingPath = data?.sidings ?? undefined
  const sidings = sidingPath
    ? sidingPath.map(item => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn?.trim() ?? undefined
        const nameEn = path?.nameEn?.trim() ?? undefined
        const name = mn ? (nameMn ? nameMn : nameEn) : nameEn ? nameEn : nameMn

        const slug = nameEn
          ? nameEn.replace(/\s+/g, "-").toLowerCase()
          : nameMn.replace(/\s+/g, "-").toLowerCase()

        const productType = "siding"

        const sharpPath = path?.Image ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        return { name, image, productType, slug }
      })
    : undefined

  const arrangedSidings = sidings.sort(function (a, b) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  const cards = arrangedSidings.map((item, index) => {
    return <Card key={index} data={item} />
  })

  return (
    <Layout>
      {/* <PageHeader title={name} text={text} image={image} tall /> */}
      <PageTitle title={name} text={text} />
      <div className="grid max-w-screen-lg grid-cols-2 gap-6 px-4 pt-12 pb-24 mx-auto md:grid-cols-4 sm:grid-cols-3">
        {cards}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SingleSidingType($id: String!) {
    type: airtable(id: { eq: $id }) {
      data {
        nameMn: Name_mongol
        nameEn: Name_english
        textMn: Mongol_tailbar
        textEn: English_tailbar
        Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED, quality: 100)
            }
          }
        }
        sidings: Siding {
          data {
            nameMn: Name_mongol
            nameEn: Name_english
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SidingTypeTemplate
